<template>
  <div
    class="
      widgetContainer
      header-white
      widgetContainer--scrollable
      statementDetail
    ">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <p class="title">
          {{ $t('statements') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <div class="statementDetail__blank" v-if="hasNoStatement">
        <p>{{ $t('no_statement_text') }}</p>
      </div>
      <div class="statementDetail__actions" v-else>
        <ul>
          <li v-for="(item, index) in getStatements.data" :key="index">
            <div class="info">
              <p class="title">
                {{
                  `${item.year}-${
                    ('' + item.month).length == 1
                      ? '0' + item.month
                      : item.month
                  }-01 00:00:00` | date('MMM yyyy')
                }}
              </p>
              <p class="description">
                Generated on {{ item.createdAt | date('MMM d, yyyy') }}
              </p>
            </div>

            <div class="action" @click="download(item.year, item.month)">
              <p class="select">
                {{ $t('statement_view') }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  created() {
    if (this.getSelectedAccount) {
      const loader = this.showLoader();
      this.fetchStatements(this.getSelectedAccount.id)
        .then(() => {})
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    }
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount', 'getStatements']),
    hasNoStatement() {
      return !this.getStatements.data.length;
    },
  },
  methods: {
    ...mapActions('account', ['fetchStatements', 'getStatementByMonth']),
    download(year, month) {
      const loader = this.showLoader();
      this.getStatementByMonth({
        year,
        month,
        accountId: this.getSelectedAccount.id,
        format: 'pdf'
      })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${year}-${month}-statement.pdf`;
          a.click();
          a.remove();
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    goBack(){
      this.$emit('back');
    },
    close(){
      this.$emit('close');
    },
  }
};
</script>
<style lang="scss" scoped>
.statementDetail {
  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
  }

  .loginHeader {
    .submit {
      margin-left: auto;
    }
  }

  &__blank {
    p {
      color: var(--branding);
      text-align: center;
      padding-top: 20px;
    }
  }

  &__actions {
    .main-title {
      text-transform: uppercase;
      color: rgba(#3c3c43, 0.6);
      font-size: 12px;
      line-height: 16px;
      padding: 8px 0 8px 0;
    }

    li {
      // border-radius: var(--radius);
      background-color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid var(--light-grey-3);

      &:last-of-type {
        border-bottom: none;
      }

      .info {
        margin: 0 auto 0 0px;

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        .description {
          font-size: 12px;
          line-height: 16px;
          color: var(--branding);
        }
      }

      .action {
        cursor: pointer;

        .select {
          color: var(--branding);
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          text-decoration: underline;
          text-transform: uppercase;
        }

        .icon-tick {
          color: var(--branding);
          font-size: 14px;
          display: none;
        }
      }

      .icon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        background-color: var(--branding);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        span {
          color: #fff;
        }
      }

      .icon-arrow-right {
        margin-left: auto;
        color: #c7c7c9;
        font-size: 13px;
      }
    }
  }
}
</style>
